import React from 'react';
import { css, injectGlobal } from 'emotion';
import _ from 'lodash';
import { connect } from 'react-redux';
import SliderModal from './SliderModal';
import PaymentRadio from './PaymentRadio';
import { urls as urls, generateToken, urlType } from '../../api/urls';
import { loginUser, updateUser, fetchProfile, flushUserState, updateLicense, updateProfile } from '../../actions/userActions';
import { updateSelectedVendor, updateRemarks } from '../../actions/orderActions';
import Moment from 'moment';
import { flushTripState, fetchTrips } from '../../actions/tripActions';
import { updatePaymentId, bidSubmitted, updateBidId, submitBid, flushOrderState, logTransaction, blockMyChoizeVehicle, placeHolderOrder } from '../../actions/orderActions';
import { navigate } from "gatsby";
import { PulseLoader } from 'react-loaders-spinners';
import {sha256} from 'js-sha256';
import ImageUploader from 'react-images-upload';
import S3FileUpload from 'react-s3';
import axios from 'axios';
import Popup from "reactjs-popup";
import queryString from "query-string"
import ReCAPTCHA from "react-google-recaptcha";
import './popup.css'
injectGlobal(`.row{
    max-width: 82rem;
    @media (webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
        //Retina Specific
        max-width: 75rem;
    }
}`)

/*global tw*/
/*global google*/

const styles = {
    listWrapper: css`
        ${tw`w-full bg-white overflow-y-scroll scrolling-touch`};
        border-radius: 16px 16px 0 0;
        height: 91.4511742891vh;
        // transform: translateY(-1vh);
        padding-bottom: 100px;
        box-shadow: 0 0 25px -2px rgba(0,0,0,0.3);
         -webkit-overflow-scrolling: touch;
    `,
    row: css`
        ${tw`w-full flex flex-wrap justify-between items-center px-6 lg:px-0 -mx-2 lg:mx-auto`};   
        max-width: 82rem;
        @media (webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
            //Retina Specific
            max-width: 75rem;
        }
    `,
    carSpecs: css`
        opacity: 0.5;
        font-family: ProximaNova-Regular;
        color: #222222;
        letter-spacing: 0.8px;
        text-transform: uppercase;
        margin-right: 7.5px;
        border-box: border;
        font-size: 11px;

        vertical-align: middle;
        &:after{
            display: inline-block;
            content: "|";
            margin-left: 7.5px;
            
        }
        &:last-child:after{
            opacity: 0;
        }
        @media(min-width: 992px){
            font-size: 14px;

        }
    `,
    carTitle: css`
        ${tw`my-1`}
        opacity: 0.77;
        font-family: Chromatica-Bold;
        font-size: 25.89px;
        color: #222222;
        letter-spacing: -0.83px;
    `,
    carImage: css`
        height: 18vh;
        ${tw`-ml-2`}
    `,
    vendorLogo: css`
        ${tw`h-8 lg:h-16`};
        @media(min-width: 992px){
            height: 1rem;
            width: 10rem;
        }
    `,
    totalPrice: css`
        opacity: 0.9;
        font-family: Chromatica-Bold;
        @media(min-width: 992px){
            font-size: 36.65px;
        }
        font-size: 18px;
        color: #222222;
        letter-spacing: -1.18px;
    `,
    title: css`
        ${tw`w-full flex items-center font-medium justify-between`};
        opacity: 0.65;
        font-size: 18.65px;
        color: #222222;
        letter-spacing: -0.4px;
    `,
    itineraryLine: css`
        ${tw`w-full flex flex-wrap justify-between py-3 lg:py-4 px-6`}
        // border-bottom: 1px solid #F0F2F5;
    `,
    choiceFilterButton: css`
         ${tw`sticky flex items-center text-center px-6 py-2 z-100 pin-b pin-x mx-auto mb-6 md:mb-4 lg:mb-8 justify-center`}; 
        box-shadow: 0 3px 25px rgba(0,0,0,0.2); 
        border-radius: 100px;
        letter-spacing: 0.8px;
        transition: all 0.25s ease-in-out;
        background-image: linear-gradient(115deg, #AA0BCA 10%, #7D0EDE 39%);
        width: 95vw;
        height: 3rem;
        @media (min-width: 992px){
            width: 33vw;
            height: 4rem; 
        }
        &:hover{
            cursor: pointer;
            transform: scale(0.98);
        }
        p{
            ${tw`font-medium text-white text-center`}
            @media(min-width: 992px){
                font-size: 1.2rem;
            }
        }
    `,
}

class ProfileSlider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            vendors: {},
            selectedVendor: {},
            showOverlay: false,
            showBookingForm: false,
            name: "",
            licenseNo: "",
            identity: "",
            phone: "",
            email: "",
            otp: "",
            isUserLoggedIn: false,
            countryCode: "+91",
            step: 1,
            payAdvance: false,
            payFull: true,
            error: '',
            remarks: '',
            isAdvancePaid: false,
            isSubmitting: false,
            menuOpen: false,
            idType: 'licenseNo',
            redeemPoints: false,
            redeemedPoints: 0,
            additionalActivityList: [],
            locationList: null,
            disablePick: true,
            disableDrop: true,
            pickAddress: '',
            dropAddress: '',
            fetchedMyChoizePricing: false,
            pickLocation: null,
            dropLocation: null,
            activityKeyList: [],
            myDropAddressMsg: '',
            myPickAddressMsg: '',
            licenseFront: '',
            licenseBack: '',
            panCard: '',
            aadharFront: '',
            aadharBack: '',
            passportDetailsPage: '',
            aadharNo: '',
            panNumber: '',
            passportNo: '',
            uploadingLF: false,
            uploadingLB: false,
            uploadingPan: false,
            uploadingAF: false,
            uploadingAB: false,
            uploadingPDP: false,
            fetchingMyChoizeLocAndActivity: false,
            proceedUpload: false,
            fetchingMyChoizeVehicle: false,
            uploadingUB: false,
            utilityBill: '',
            utilityNo: '',
            myChoizeBlockedVehicle: {},
            driverCharge: null,
            nightCharge: null,
            completeReward: false,
            customercare: '',
            popupOpen: '',
            custOtp: false,
            captchaValue: null
        };
        this.accordionWrapper = null;
        this.handleChange = this.handleChange.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.blockMyChoizeVehicle = this.blockMyChoizeVehicle.bind(this);
    }

    componentDidUpdate(prevState, prevProps) {
        
        // if(this.props.isSubmitting!==prevProps.isSubmitting)
        // {
        // this.setState({isSubmitting: this.props.isSubmitting, step: 0})
        // }
    }

    componentWillMount(){
        this.setState({ selectedVendor: this.props.selectedVendor, showBookingForm: this.props.showBookingForm})
        if (this.props.isUserLoggedIn) {
            this.setState({
                isUserLoggedIn: true,
                name: this.props.userName,
                phone: this.props.userPhone,
                email: this.props.userEmail,
                licenseNo:this.props.user.licenseNo,
                passportNo:this.props.user.hasOwnProperty('passportNo') ? this.props.user.passportNo : '',
                aadharNo:this.props.user.hasOwnProperty('aadharNo') ? this.props.user.aadharNo : '',
                panNumber:this.props.user.hasOwnProperty('panNumber') ? this.props.user.panNumber : '',
                utilityNo:this.props.user.hasOwnProperty('utilityNo') ? this.props.user.utilityNo : '',
                step: this.props.vendors.category === "SELF DRIVE" ? 5 : 7
            });
            this.props.user.hasOwnProperty('licenseFront') && this.props.user.licenseFront !== '' && this.setState({ licenseFront: this.props.user.licenseFront, uploadingLF: false });
            this.props.user.hasOwnProperty('licenseBack') && this.props.user.licenseBack !== '' && this.setState({ licenseBack: this.props.user.licenseBack, uploadingLB: false });
            this.props.user.hasOwnProperty('panCard') && this.props.user.panCard !== '' && this.setState({ panCard: this.props.user.panCard, uploadingPan: false });
            this.props.user.hasOwnProperty('aadharFront') && this.props.user.aadharFront !== '' && this.setState({ aadharFront: this.props.user.aadharFront, uploadingAF: false });
            this.props.user.hasOwnProperty('aadharBack') && this.props.user.aadharBack !== '' && this.setState({ aadharBack: this.props.user.aadharBack, uploadingAB: false });
            this.props.user.hasOwnProperty('passportDetailsPage') && this.props.user.passportDetailsPage !== '' && this.setState({ passportDetailsPage: this.props.user.passportDetailsPage,uploadingPDP: false});
            this.props.user.hasOwnProperty('utilityBill') && this.props.user.utilityBill !== '' && this.setState({ utilityBill: this.props.user.utilityBill,uploadingUB: false});
            let uploadCount = 0;
            if(this.state.licenseFront !== '' && this.state.licenseBack !== '' && this.state.licenseNo !== ''){
                uploadCount +=2;
            }
            if(this.state.panCard !== '' && this.state.panNumber !== ''){
                uploadCount +=2;
            }
            if(this.state.aadharFront !== '' && this.state.aadharBack !== '' && this.state.aadharNo !== ''){
                uploadCount +=2;
            }
            if(this.state.passportDetailsPage !== '' && this.state.passportNo !== ''){
                uploadCount +=2;
            }
            if(this.state.utilityBill !== '' && this.state.utilityNo !== ''){
                uploadCount +=2;
            }
            if(uploadCount === 6 || uploadCount === 8 || uploadCount === 10){
                this.setState({ proceedUpload: true})
                uploadCount = 8;
            }
        }
        this.setState({ pickAddress: this.props.myChoize.pickup.hasOwnProperty('loc') && this.props.myChoize.pickup.loc !== '' ? this.props.myChoize.pickup.loc : '', dropAddress: this.props.myChoize.dest.hasOwnProperty('loc') && this.props.myChoize.dest.loc !== '' ? this.props.myChoize.dest.loc : ''})

       

    }

    handlePrev = () => {

        if (this.state.step > 1) {
            if (this.props.vendors.category === "SELF DRIVE") {
                if (this.state.step === 7) {
                    this.setState({ idType: 'aadharNo' })
                }
                else if (this.state.step === 6) {
                    this.setState({ idType: 'licenseNo' })
                }
                // if (this.state.step === 7 && this.state.prev === 5) {
                //     this.setState({ step: 5, error: '' });
                // }
                // else {
                //     this.setState({ step: this.state.step - 1, error: '' });
                // }
                if(this.state.step === 9){
                this.setState({ step: this.state.step - 2, error: '' });
                }
                else {
                this.setState({ step: this.state.step - 1, error: '' });
                }


            }

            else if (this.props.vendors.category !== "SELF DRIVE") {
                if (this.state.step === 7) {
                    this.setState({ step: this.state.step - 3, error: '' });
                }

                else {
                    this.setState({ step: this.state.step - 1, error: '' });
                }
            }
        }
        else {
            return;
        }
    }

    openRazorpay() {

        let self = this;
        let options = {
            // "key": "rzp_test_FtZndiI5LLQGv6", //test key
              "key": "rzp_live_H8iLGrgI8ctwLj", //Production key
            "amount": Math.trunc(
                this.state.redeemPoints
                    ?
                    (this.state.payFull
                        ?
                        (this.state.selectedVendor.hasOwnProperty('security_deposit')
                            ?
                            (((this.state.selectedVendor.ride_amt - this.state.selectedVendor.couponValue) - this.props.user.profile.redeemablePoints) > 0 ? ((this.state.selectedVendor.ride_amt - this.state.selectedVendor.couponValue) - this.props.user.profile.redeemablePoints) : (this.state.selectedVendor.hasOwnProperty('security_deposit') ? this.state.selectedVendor.security_deposit  : 0))
                            :
                            ((this.state.selectedVendor.discountedValue - this.props.user.profile.redeemablePoints) > 0 ? (this.state.selectedVendor.discountedValue - this.props.user.profile.redeemablePoints) : ((this.state.selectedVendor.hasOwnProperty('security_deposit') ? this.state.selectedVendor.security_deposit : 0)))
                        )
                        :
                        ((this.state.selectedVendor.advance_amount - this.props.user.profile.redeemablePoints) > 0 ? (this.state.selectedVendor.advance_amount - this.props.user.profile.redeemablePoints) : ((this.state.selectedVendor.hasOwnProperty('security_deposit') ? this.state.selectedVendor.security_deposit  : 0)))
                    )
                    :
                    (this.state.payFull ? (this.state.selectedVendor.hasOwnProperty('security_deposit') ?
                        (this.state.selectedVendor.ride_amt - this.state.selectedVendor.couponValue) : this.state.selectedVendor.discountedValue) : this.state.selectedVendor.advance_amount)
            ) * 100, // 2000 paise = INR 20
            "name": "Zyppys",
            "description": "Trip Advance",
            "handler": function (response) {
                // eslint-disable-next-line
                analytics.track('Payment Successful', { category: 'payment', label: response.razorpay_payment_id });
                self.props.dispatch(logTransaction('Razorpay Payment Success', response.razorpay_payment_id));
                self.setState({ isAdvancePaid: true }, function () { self.props.dispatch(updatePaymentId(response.razorpay_payment_id)); });
                console.log("internal",self.state.isAdvancePaid)
                self.props.submitBid(self.state.payFull,self.state.redeemPoints, self.state.isAdvancePaid, self.state.myChoizeBlockedVehicle);
            },
            "prefill": {
                "name": this.state.name,
                "email": this.state.email,
                "contact": this.state.phone
            },
            "notes": {
                "address": "Hello World"
            },
            "theme": {
                "color": '#9561e2'
            }
        };
        this.props.dispatch(logTransaction('Razorpay Opened', options));
        // eslint-disable-next-line
        var rzp = new Razorpay(options);
        rzp.open();
    }

    authenticateOtp = () => {
        this.setState({ error: '' });
        let self = this;
        
        let url = urls.authOtpUrl;
        let authinput = {    
            "otp": this.state.otp,
            "loginType": "CUSTOMER"
        }
        // let address = { pick: this.state.pickAddress, drop: this.state.dropAddress };
        let address = { pick: this.state.pickAddress, drop: this.state.dropAddress, locationId: this.props.locationId };
        axios.post(url, authinput)
            .then(function (response) {
                
                // let self = this;
                if (response.data.statusCD === 200) {
                    if(self.state.remarks === 'customer care') {
                        self.setState({ isSubmitting: true, step: 0 })
                        self.props.dispatch(logTransaction('Customer Care Authenticated', response));
                        self.props.dispatch(placeHolderOrder(address, self.state.selectedVendor, self.state.myChoizeBlockedVehicle));
                        setTimeout(() => {
                            self.props.submitBid(self.state.payFull, self.state.redeemPoints, self.state.isAdvancePaid, self.state.myChoizeBlockedVehicle);
                        }, 10000)
                    }
                    else {
                    self.props.dispatch(logTransaction('User Authenticated', response));
                    self.setState({ isUserLoggedIn: true });
                    let user = response.data.responseData;
                    console.log(response);
                    // eslint-disable-next-line
                    analytics.track('otp', { category: 'user name', label: sha256(self.state.otp) });
                    // eslint-disable-next-line
                    analytics.identify("User Authenticated", { name: self.state.name, email: self.state.email, phone: self.state.phone, id: user.id });
                    // storeKey('user', { name: user.name, phone: user.mobileNo, email: user.emailId, id: user.id });
                    // eslint-disable-next-line
                    analytics.track('User Logged In');
                    if (!self.props.isUserLoggedIn) {
                        self.props.dispatch(updateUser({ name: self.state.name, phone: self.state.phone, email: self.state.email }));
                        self.props.dispatch(fetchProfile());
                        // if(this.props.vendors.category === "SELF DRIVE"){
                        //     self.props.dispatch(updateLicense({licenseNo:self.state.licenseNo,idType:self.state.idType,[self.state.idType]:self.state.identity}));
                        // }
                        if (user.hasOwnProperty('licenseNo') && user.licenseNo !== '') {
                            self.props.dispatch(updateUser({ licenseNo: user.licenseNo, passportNo: user.hasOwnProperty('passportNo') ? user.passportNo : '', aadharNo: user.hasOwnProperty('aadharNo') ? user.aadharNo : '', panNumber: user.hasOwnProperty('panNumber') ? user.panNumber : '' }));
                            self.setState({
                                licenseNo: user.licenseNo,
                                passportNo: user.hasOwnProperty('passportNo') ? user.passportNo : '',
                                aadharNo: user.hasOwnProperty('aadharNo') ? user.aadharNo : '',
                                panNumber: user.hasOwnProperty('panNumber') ? user.panNumber : '',
                                utilityNo: user.hasOwnProperty('utilityNo') ? user.utilityNo : ''

                            });
                        }
                        user.hasOwnProperty('licenseFront') && self.setState({ licenseFront: user.licenseFront, uploadingLF: false });
                        user.hasOwnProperty('licenseBack') && self.setState({ licenseBack: user.licenseBack, uploadingLB: false });
                        user.hasOwnProperty('panCard') && self.setState({ panCard: user.panCard, uploadingPan: false });
                        user.hasOwnProperty('aadharFront') && self.setState({ aadharFront: user.aadharFront, uploadingAF: false });
                        user.hasOwnProperty('aadharBack') && self.setState({ aadharBack: user.aadharBack, uploadingAB: false });
                        user.hasOwnProperty('passportDetailsPage') && self.setState({ passportDetailsPage: user.passportDetailsPage, uploadingPDP: false });
                        user.hasOwnProperty('utilityBill') && self.setState({ utilityBill: user.utilityBill, uploadingUB: false });
                        let uploadCount = 0;
                        if (self.state.licenseFront !== '' && self.state.licenseBack !== '' && self.state.licenseNo !== '') {
                            uploadCount += 2;
                        }
                        if (self.state.panCard !== '' && self.state.panNumber !== '') {
                            uploadCount += 2;
                        }
                        if (self.state.aadharFront !== '' && self.state.aadharBack !== '' && self.state.aadharNo !== '') {
                            uploadCount += 2;
                        }
                        if (self.state.passportDetailsPage !== '' && self.state.passportNo !== '') {
                            uploadCount += 2;
                        }
                        if (self.state.utilityBill !== '' && self.state.utilityNo !== '') {
                            uploadCount += 2;
                        }
                        if (uploadCount === 6 || uploadCount === 8 || uploadCount === 10) {
                            self.setState({ proceedUpload: true })
                            uploadCount = 8;
                        }
                        self.props.dispatch(loginUser());

                    }
                    if (self.props.selectedCar.category === 'SELF DRIVE') {
                        //self.props.dispatch(updateLicense({ licenseNo: self.state.licenseNo, idType: self.state.idType, [self.state.idType]: self.state.identity }));
                        // if (self.state.selectedVendor.account_name === 'MyChoize') {
                        //     // post(vendor.submit_request_url,vendor.submit_request_body,'post',this.props);
                        //     post(self.state.selectedVendor.submit_request_url, self.state.selectedVendor.submit_request_body, 'post', self.props.ride);
                        //     self.setState({ showBookingForm: false, showOverlay: false });
                        // }
                        if (self.state.selectedVendor.advance_amount > 0 || self.state.selectedVendor.account_name === 'MyChoize') {
                            // self.openRazorpay();
                            self.setState({ step: self.state.step + 1 });
                        }
                        else {
                            self.props.submitBid(this.state.payFull, this.state.redeemPoints, this.state.isAdvancePaid, this.state.myChoizeBlockedVehicle);
                            self.setState({ showBookingForm: false, showOverlay: false });
                        }
                    }
                    else {
                        if (self.state.selectedVendor.advance_amount > 0) {
                            // self.openRazorpay();
                            self.setState({ step: self.state.step + 3 });
                        }
                        else {
                            self.props.submitBid(this.state.payFull, this.state.redeemPoints, this.state.isAdvancePaid, this.state.myChoizeBlockedVehicle);
                            self.setState({ showBookingForm: false, showOverlay: false });
                        }
                    }
                    self.props.dispatch(fetchTrips());
                }
                }

                else if (response.data.statusCD === 401) {
                    // alert("Invalid OTP");
                    self.setState({ error: 'Invalid OTP' });

                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }

    blockMyChoizeVehicle() {
        let redeemedPoints =
            (this.state.redeemPoints
                ?
                (this.state.payFull
                    ?
                    (this.state.selectedVendor.hasOwnProperty('security_deposit')
                        ?
                        (((this.state.selectedVendor.ride_amt - this.state.selectedVendor.couponValue) - this.props.user.profile.redeemablePoints) > 0 ? ((this.state.selectedVendor.ride_amt - this.state.selectedVendor.couponValue) - this.props.user.profile.redeemablePoints) : (1))
                        :
                        ((this.state.selectedVendor.discountedValue - this.props.user.profile.redeemablePoints) > 0 ? (this.state.selectedVendor.discountedValue - this.props.user.profile.redeemablePoints) : (1))
                    )
                    :
                    ((this.state.selectedVendor.advance_amount - this.props.user.profile.redeemablePoints) > 0 ? (this.state.selectedVendor.advance_amount - this.props.user.profile.redeemablePoints) : (1))
                )
                :
                (this.state.payFull ? (this.state.selectedVendor.hasOwnProperty('security_deposit') ?
                    (this.state.selectedVendor.ride_amt - this.state.selectedVendor.couponValue) : this.state.selectedVendor.discountedValue) : this.state.selectedVendor.advance_amount)
            );
        console.log(redeemedPoints);
        // let address = { pick: this.state.pickAddress, drop: this.state.dropAddress };
        let address = { pick: this.state.pickAddress, drop: this.state.dropAddress, locationId: this.props.locationId };
        console.log("Address",address)
        if (redeemedPoints === 1) {
            this.props.dispatch(blockMyChoizeVehicle(address, this.state.selectedVendor, { redeemedPoints: this.state.payFull ? (this.state.selectedVendor.ride_amt - this.state.selectedVendor.couponValue) : (this.state.selectedVendor.advance_amount) }, this.blockSuccess, this.blockError));
        }
        else {
            this.props.dispatch(blockMyChoizeVehicle(address, this.state.selectedVendor, { redeemedPoints: this.state.redeemPoints ? this.props.user.profile.redeemablePoints : 0 }, this.blockSuccess, this.blockError));
        }
    }

    blockSuccess = (data) => {
        console.log(data);
        this.setState({ myChoizeBlockedVehicle: data, fetchingMyChoizeVehicle: false });
    }

    blockError = (data) => {
        console.log(data);
        alert("This vehicle is sold out...Please try after some time.");
        navigate(`/carsList`);
    }

    generateToken = () => {
        let url = generateToken;
        let config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                // 'Authorization': urlType === 'Production' ? "Basic enlwcHlzX3JldGFpbF9jbGllbnQ6enlwcHlzX3JldGFpbF9zZWNyZXQ=" : "Basic enlwcHlzX3JldGFpbF9jbGllbnQ6enlwcHlzX3JldGFpbF9zZWNyZXQ="
                'Authorization': "Basic enlwcHlzX3JldGFpbF9jbGllbnQ6enlwcHlzX3JldGFpbF9zZWNyZXQ="
            },
            // auth: {
            //     username: 'zyppys_retail_client',
            //     password: 'zyppys_retail_secret'
            // }
        }

        let data;

        // Test Credentials
        if (urlType === 'Test') {
            // data = {
            //     grant_type: "password",
            //     username: "javainuse-user",
            //     password: "javainuse-pass"
            // }
            data = {
                grant_type: "password",
                username: "zyppys_retail_portal_user",
                password: "zyppys_retail_portal_pwd"
            }
        }

        // Production Credentials
        if (urlType === 'Production') {
            data = {
                grant_type: "password",
                username: "zyppys_retail_portal_user",
                password: "zyppys_retail_portal_pwd"
            }
        }
        console.log("Generate Token URL ", url, "\nGenerate Token Data ", data, "\nGenerate Token Config", config )
        axios.post(url, queryString.stringify(data), config)
        .then( response => {
            console.log("Generate Token Response", response)
            this.sendOtp(response.data.access_token);
        })
        .catch(error => {
            console.log("Generate Token error", error);
            this.sendOtp("");
        })
    }

    sendOtp = (token) => {
        ;
        let self = this;
        let url = urls.sendOTPPortal;
        // this.otpInput.focus();
        let otpInput = {};
        if(this.state.remarks==='customer care'){
             otpInput = {
                "user": {
                    "name": "",
                    "emailId": "",
                    "mobileNo":  Number(this.state.customercare) ,
                    "countryCode": Number(this.state.countryCode),
                    "fcmId": this.props.fcmToken ? this.props.fcmToken : ''
                },
                "loginType": "CUSTOMER"
            }
        }
        else{
             otpInput = {
                "user": {
                    "name": this.state.name,
                    "emailId": this.state.email,
                    "mobileNo": Number(this.state.phone),
                    "countryCode": Number(this.state.countryCode),
                    "fcmId": this.props.fcmToken ? this.props.fcmToken : ''
                },
                "loginType": "CUSTOMER"
            }
        }
        let config = {
            headers: {
                Authorization: `Bearer ${token}` 
               }
        }
        console.log("Send OTP URL ", url, "\nSend OTP Data", otpInput, "\nSend OTP Config", config)
        self.props.dispatch(updateUser({ name: self.state.name, phone: self.state.phone, email: self.state.email }));
        axios.post(url, otpInput, config)
            .then(function (response) {
                if (self.state.remarks !== 'customer care') {
                    self.props.dispatch(logTransaction('OTP Sent', otpInput));
                }
                else {
                    self.props.dispatch(logTransaction('Customer Care OTP Sent', otpInput));
                }
                console.log(response);
            })
            .catch(error => {
                console.log("Send OTP Error", error)
            })
    }

    handleNext = () => {
        this.setState({ error: '' });
        if (this.state.step === 1) {
            if (this.state.name.length < 2) {
                this.setState({ error: "Please Enter a Valid Name" });
                return;
            }
            this.setState({ step: this.state.step + 1 });
            // eslint-disable-next-line
            analytics.track('name', { category: 'user name', label: sha256(this.state.name) });

        }
        else if (this.state.step === 2) {
            const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
            // if(this.state.email.length===0 || !this.state.email.includes('@')){
            //     this.setState({ error: "Please Enter a Valid Email" });
            //     return;
            // }

            if (!(reg.test(this.state.email))) {
                this.setState({ error: "Please Enter a Valid Email" });
                return;
            }
            // if (this.props.vendors.category === "SELF DRIVE") {
            //     this.setState({ step: this.state.step + 1 });
            // }
            // else {
            this.setState({ step: this.state.step + 1 });
            // eslint-disable-next-line
            analytics.track('email', { category: 'user name', label: sha256(this.state.email) });

            // }
        }
        else if (this.state.step === 3) {
            if (this.state.phone.length !== 10) {
                this.setState({ error: "Please Enter a Valid Phone Number" });
                return;
            }
            if (this.state.captchaValue === null) {
                this.setState({ error: "Please verify that you are not a robot" });
                return;
            }
            this.verifyCaptcha();
            // this.generateToken()
            // this.sendOtp();
            // eslint-disable-next-line
            analytics.track('phone', { category: 'user name', label: sha256(this.state.phone) });
            // this.setState({ step: this.state.step + 1 });
        }
        else if (this.state.step === 4) {
            if (this.state.otp === "") {
                this.setState({ error: "Please Enter a Valid OTP" });
                return;
            }
            this.setState({ idType: 'licenseNo' })
            this.authenticateOtp();
        }
        else if (this.state.step === 9) {
            this.setState({otp:''})
            if (this.state.customercare.length !== 10) {
                this.setState({ error: "Please Enter a Valid Phone Number"});
                return;
            }
            this.generateToken()
            // this.sendOtp();
            // eslint-disable-next-line
            this.setState({ step: this.state.step + 1 });
        }
        else if (this.state.step === 10) {
            this.authenticateOtp();
        }
        // else if (this.state.step === 5) {
        //     if (this.state.licenseNo === "") {
        //         this.setState({ error: "Please Enter a Valid License Number" });
        //         return;
        //     }
        //     this.setState({ step: this.state.step + 1 });
        // }
        else if (this.state.step === 5) {

            // this.props.dispatch(updateUser({ licenseNo: this.state.licenseNo , passportNo:this.state.hasOwnProperty('passportNo') ? this.state.passportNo : '', aadharNo:this.state.hasOwnProperty('aadharNo') ? this.state.aadharNo : '', panNumber:this.state.hasOwnProperty('panNumber') ? this.state.panNumber : ''}));                        
            let uploadCount = 0;
            if (this.state.licenseFront !== '' && this.state.licenseBack !== '' && this.state.licenseNo !== '') {
                uploadCount += 2;
            }
            // if(this.state.panCard !== '' && this.state.panNumber !== ''){
            //     uploadCount +=2;
            // }
            // if(this.state.aadharFront !== '' && this.state.aadharBack !== '' && this.state.aadharNo !== ''){
            //     uploadCount +=2;
            // }
            // if(this.state.passportDetailsPage !== '' && this.state.passportNo !== ''){
            //     uploadCount +=2;
            // }
            // if(this.state.utilityBill !== '' && this.state.utilityNo !== ''){
            //     uploadCount +=2;
            // }
            if (uploadCount === 2) {
                this.setState({ proceedUpload: true })
                uploadCount = 2;
                this.props.dispatch(updateUser({ 
                    licenseNo: this.state.licenseNo ,
                //     passportNo: this.state.passportNo ,
                //     aadharNo: this.state.aadharNo ,
                //     panNumber: this.state.panNumber ,
                //     utilityNo: this.state.utilityNo,
                    licenseFront: this.state.licenseFront,
                    licenseBack: this.state.licenseBack
                //     passportDetailsPage: this.state.passportDetailsPage,
                //     aadharFront: this.state.aadharFront,
                //     aadharBack: this.state.aadharBack,
                //     panCard: this.state.panCard,
                //     utilityBill: this.state.utilityBill
                    }));

                // if(this.props.selectedVendor.account_name === 'MyChoize'){
                //     this.setState({fetchingMyChoizeVehicle: true })
                //     this.blockMyChoizeVehicle();
                // }
            }
            console.log(uploadCount);
            if (uploadCount !== 2) {
                alert('Please upload your driving license details');
                // this.setState({ error: "Please upload atleast 3 Id's" });
                return;
            }
            else if (this.props.user.phone !== '') {
                this.setState({ step: 6, prev: 5, idType: 'aadharNo' });
            }
            else {
                this.setState({ step: this.state.step + 1, idType: 'aadharNo' });
            }
        }
        else if (this.state.step === 6) {
            let uploadCount = 0;
            if (this.state.aadharFront !== '' && this.state.aadharBack !== '' && this.state.aadharNo !== '') {
                uploadCount += 2;
            }
            if (uploadCount === 2) {
                this.setState({ proceedUpload: true })
                uploadCount = 4;
                this.props.dispatch(updateUser({
                    // licenseNo: this.state.licenseNo,
                    aadharNo: this.state.aadharNo,
                    // licenseFront: this.state.licenseFront,
                    // licenseBack: this.state.licenseBack,
                    aadharFront: this.state.aadharFront,
                    aadharBack: this.state.aadharBack,
                }));
                if (this.props.selectedVendor.account_name === 'MyChoize') {
                    this.setState({ fetchingMyChoizeVehicle: true })
                    this.blockMyChoizeVehicle();
                }
            }
            if (uploadCount !== 4) {
                alert('Please upload your aadhar/pan details');
                // this.setState({ error: "Please upload atleast 3 Id's" });
                return;
            }
            else {
                this.setState({ step: this.state.step + 1 });
            }
        }

        else if (this.state.step === 7) {
            // logic to check location is not empty


            // if ((this.props.ride.rentalType === 1 && this.props.selectedVendor.account_name !== 'MyChoize') || (this.props.ride.rentalType === 2 && this.props.selectedVendor.account_name === 'MyChoize')) {
            //     if (this.state.remarks !== '') {
            //         this.props.dispatch(updateRemarks(this.state.remarks));
            //     }

            //     //submitting order without advance payment for only zyppys if done by customer care
            //     if (this.state.remarks === 'customer care') {
            //         this.submitBid();
            //     }
            //     else {
            //         this.setState({ step: this.state.step + 1 });
            //     }
            // }
            // else {
            //     if (this.props.ride.rentalType === 3) {
            //         if (this.props.myChoize.pickup.hasOwnProperty('loc') && this.props.myChoize.pickup.loc !== '' && this.props.myChoize.dest.hasOwnProperty('loc') && this.props.myChoize.dest.loc !== '') {
            //             this.setState({ step: this.state.step + 1 });
            //         }
            //         else {
            //             alert("Please enter location");
            //         }
            //     }
            //     else{
            //         if (this.props.myChoize.pickup.hasOwnProperty('loc') && this.props.myChoize.pickup.loc !== '') {
            //             this.setState({ step: this.state.step + 1 });
            //         }
            //         else {
            //             alert("Please enter location");
            //         }
            //     }
            // }
            if (this.state.remarks !== '') {
                this.props.dispatch(updateRemarks(this.state.remarks));
            }

            //submitting order without advance payment for only zyppys if done by customer care
            if (this.state.remarks === 'customer care') {
                this.setState({step: 9})

                // this.props.submitBid(this.state.payFull, this.state.redeemPoints);
        // return (       <Popup open={this.state.remarks === 'customer care'}
        //     closeOnDocumentClick
        //     onClose={()=>{this.setState({cancelOpen: false})}}>
        //   <div className="text-sm border border-gray-600 px-1 py-1">
        //   <div className="modal" style={{ position: 'absolute', right: 25 }}>
        //       <a className="close" onClick={()=>{this.setState({cancelOpen: false})}}>
        //         <div style={{ borderStyle: 'groove', textAlign: 'center',color:'gray', width: 30, cursor: 'pointer' }}>Close</div>
        //       </a>
        //     </div>
        //     <div className="p-4 ">
          
          
        //   <p>Hello World!</p>
        //     </div>
        // </div>
        
    
        // </Popup> )
            }
            else {
                this.setState({ step: this.state.step + 1 });
            }
        }
        else if (this.state.step === 8) {
            let placeHolder = this.props.placeHolder;
            let ride = this.props.ride;
            let selectedVendor = this.state.selectedVendor;
            let selectedCar = this.props.selectedCar;
            // let address = { pick: this.state.pickAddress, drop: this.state.dropAddress };
            let address = { pick: this.state.pickAddress, drop: this.state.dropAddress, locationId: this.props.locationId };
            let now = Moment().format('DD-MM-YYYY HH:mm:ss');
            let processStartDate = this.props.ride.currentDateTime;
            let addTime = Moment(processStartDate, 'DD-MM-YYYY HH:mm:ss').add(30, 'm').format('DD-MM-YYYY HH:mm:ss');
            console.log(addTime);
            if (Moment(now, 'DD-MM-YYYY HH:mm:ss').isAfter(Moment(addTime, 'DD-MM-YYYY HH:mm:ss'))) {
                alert("Time out! Please try again later.")
                navigate('/');
                return;
            }
            // this.setState({step:this.state.step+1});
            if (this.state.redeemPoints) {
                if (this.state.payFull) {
                    if (this.state.selectedVendor.hasOwnProperty('security_deposit')) {
                        if (((this.state.selectedVendor.ride_amt - this.state.selectedVendor.couponValue) - this.props.user.profile.redeemablePoints) <= 0) {
                            if (this.props.ride.rentalType !== 2) {
                                this.setState({ isSubmitting: true, step: 0 })
                                this.props.dispatch(placeHolderOrder(address, this.state.selectedVendor, this.state.myChoizeBlockedVehicle));
                                setTimeout(() => {
                                    this.props.submitBid(this.state.payFull, this.state.redeemPoints, this.state.isAdvancePaid, this.state.myChoizeBlockedVehicle);
                                }, 10000)
                            }
                            else {
                                if(placeHolder.pickCity === ''){
                                    this.props.dispatch(placeHolderOrder(address, this.state.selectedVendor, this.state.myChoizeBlockedVehicle));
                                }
                                else if(placeHolder.pickCity !== ride.pickup.city || placeHolder.dropCity !== ride.dest.city || placeHolder.pickAddress !== ride.pickup.loc || placeHolder.dropAddress !== ride.dest.loc || placeHolder.startDate !== ride.startDate || placeHolder.startTime !== ride.startTime || placeHolder.endDate !== ride.endDate || placeHolder.endTime !== ride.endTime || placeHolder.rentalType !== ride.rentalType || placeHolder.vendor !== selectedVendor.account_name || placeHolder.carCategotyId !== selectedCar.category_id || placeHolder.carSubCategoryId !== selectedCar.sub_category_id || (ride.rentalType === 1 && placeHolder.type !== ride.measureLookupId) || (ride.rentalType === 2 && placeHolder.type !== selectedVendor.measure_cd) || (ride.rentalType === 3 && placeHolder.type !== ride.airportType) || (ride.rentalType === 4 && placeHolder.type !== "2 hrs, 20 kms") || (ride.rentalType === 5 && placeHolder.type !== "4 hrs, 40 kms") || (ride.rentalType === 6 && placeHolder.type !== "8 hrs, 80 kms") || (ride.rentalType === 7 && placeHolder.type !== "12 hrs, 120 kms")){
                                    this.props.dispatch(placeHolderOrder(address, this.state.selectedVendor, this.state.myChoizeBlockedVehicle));
                                }
                                this.openRazorpay()
                            }
                            this.setState({ completeReward: true })

                        }
                        else {
                            if(placeHolder.pickCity === ''){
                                this.props.dispatch(placeHolderOrder(address, this.state.selectedVendor, this.state.myChoizeBlockedVehicle));
                            }
                            else if(placeHolder.pickCity !== ride.pickup.city || placeHolder.dropCity !== ride.dest.city || placeHolder.pickAddress !== ride.pickup.loc || placeHolder.dropAddress !== ride.dest.loc || placeHolder.startDate !== ride.startDate || placeHolder.startTime !== ride.startTime || placeHolder.endDate !== ride.endDate || placeHolder.endTime !== ride.endTime || placeHolder.rentalType !== ride.rentalType || placeHolder.vendor !== selectedVendor.account_name || placeHolder.carCategotyId !== selectedCar.category_id || placeHolder.carSubCategoryId !== selectedCar.sub_category_id || (ride.rentalType === 1 && placeHolder.type !== ride.measureLookupId) || (ride.rentalType === 2 && placeHolder.type !== selectedVendor.measure_cd) || (ride.rentalType === 3 && placeHolder.type !== ride.airportType) || (ride.rentalType === 4 && placeHolder.type !== "2 hrs, 20 kms") || (ride.rentalType === 5 && placeHolder.type !== "4 hrs, 40 kms") || (ride.rentalType === 6 && placeHolder.type !== "8 hrs, 80 kms") || (ride.rentalType === 7 && placeHolder.type !== "12 hrs, 120 kms")){
                                this.props.dispatch(placeHolderOrder(address, this.state.selectedVendor, this.state.myChoizeBlockedVehicle));
                            }
                            this.openRazorpay()
                        }
                    }
                    else if ((this.state.selectedVendor.discountedValue - this.props.user.profile.redeemablePoints) <= 0) {
                        if (this.props.ride.rentalType !== 2) {
                            this.setState({ isSubmitting: true, step: 0 })
                            this.props.dispatch(placeHolderOrder(address, this.state.selectedVendor, this.state.myChoizeBlockedVehicle));
                                setTimeout(() => {
                                    this.props.submitBid(this.state.payFull, this.state.redeemPoints, this.state.isAdvancePaid, this.state.myChoizeBlockedVehicle);
                                }, 10000)
                        }
                        else {
                            if(placeHolder.pickCity === ''){
                                this.props.dispatch(placeHolderOrder(address, this.state.selectedVendor, this.state.myChoizeBlockedVehicle));
                            }
                            else if(placeHolder.pickCity !== ride.pickup.city || placeHolder.dropCity !== ride.dest.city || placeHolder.pickAddress !== ride.pickup.loc || placeHolder.dropAddress !== ride.dest.loc || placeHolder.startDate !== ride.startDate || placeHolder.startTime !== ride.startTime || placeHolder.endDate !== ride.endDate || placeHolder.endTime !== ride.endTime || placeHolder.rentalType !== ride.rentalType || placeHolder.vendor !== selectedVendor.account_name || placeHolder.carCategotyId !== selectedCar.category_id || placeHolder.carSubCategoryId !== selectedCar.sub_category_id || (ride.rentalType === 1 && placeHolder.type !== ride.measureLookupId) || (ride.rentalType === 2 && placeHolder.type !== selectedVendor.measure_cd) || (ride.rentalType === 3 && placeHolder.type !== ride.airportType) || (ride.rentalType === 4 && placeHolder.type !== "2 hrs, 20 kms") || (ride.rentalType === 5 && placeHolder.type !== "4 hrs, 40 kms") || (ride.rentalType === 6 && placeHolder.type !== "8 hrs, 80 kms") || (ride.rentalType === 7 && placeHolder.type !== "12 hrs, 120 kms")){
                                this.props.dispatch(placeHolderOrder(address, this.state.selectedVendor, this.state.myChoizeBlockedVehicle));
                            }
                            this.openRazorpay()
                        }
                        this.setState({ completeReward: true })

                    }
                    else {
                        if(placeHolder.pickCity === ''){
                            this.props.dispatch(placeHolderOrder(address, this.state.selectedVendor, this.state.myChoizeBlockedVehicle));
                        }
                        else if(placeHolder.pickCity !== ride.pickup.city || placeHolder.dropCity !== ride.dest.city || placeHolder.pickAddress !== ride.pickup.loc || placeHolder.dropAddress !== ride.dest.loc || placeHolder.startDate !== ride.startDate || placeHolder.startTime !== ride.startTime || placeHolder.endDate !== ride.endDate || placeHolder.endTime !== ride.endTime || placeHolder.rentalType !== ride.rentalType || placeHolder.vendor !== selectedVendor.account_name || placeHolder.carCategotyId !== selectedCar.category_id || placeHolder.carSubCategoryId !== selectedCar.sub_category_id || (ride.rentalType === 1 && placeHolder.type !== ride.measureLookupId) || (ride.rentalType === 2 && placeHolder.type !== selectedVendor.measure_cd) || (ride.rentalType === 3 && placeHolder.type !== ride.airportType) || (ride.rentalType === 4 && placeHolder.type !== "2 hrs, 20 kms") || (ride.rentalType === 5 && placeHolder.type !== "4 hrs, 40 kms") || (ride.rentalType === 6 && placeHolder.type !== "8 hrs, 80 kms") || (ride.rentalType === 7 && placeHolder.type !== "12 hrs, 120 kms")){
                            this.props.dispatch(placeHolderOrder(address, this.state.selectedVendor, this.state.myChoizeBlockedVehicle));
                        }
                        this.openRazorpay()
                    }
                }
                else if ((this.state.selectedVendor.advance_amount - this.props.user.profile.redeemablePoints) <= 0) {
                    if (this.props.ride.rentalType !== 2) {
                        this.setState({ isSubmitting: true, step: 0 })
                        this.props.dispatch(placeHolderOrder(address, this.state.selectedVendor, this.state.myChoizeBlockedVehicle));
                                setTimeout(() => {
                                    this.props.submitBid(this.state.payFull, this.state.redeemPoints, this.state.isAdvancePaid, this.state.myChoizeBlockedVehicle);
                                }, 10000)
                    }
                    else {
                        if(placeHolder.pickCity === ''){
                            this.props.dispatch(placeHolderOrder(address, this.state.selectedVendor, this.state.myChoizeBlockedVehicle));
                        }
                        else if(placeHolder.pickCity !== ride.pickup.city || placeHolder.dropCity !== ride.dest.city || placeHolder.pickAddress !== ride.pickup.loc || placeHolder.dropAddress !== ride.dest.loc || placeHolder.startDate !== ride.startDate || placeHolder.startTime !== ride.startTime || placeHolder.endDate !== ride.endDate || placeHolder.endTime !== ride.endTime || placeHolder.rentalType !== ride.rentalType || placeHolder.vendor !== selectedVendor.account_name || placeHolder.carCategotyId !== selectedCar.category_id || placeHolder.carSubCategoryId !== selectedCar.sub_category_id || (ride.rentalType === 1 && placeHolder.type !== ride.measureLookupId) || (ride.rentalType === 2 && placeHolder.type !== selectedVendor.measure_cd) || (ride.rentalType === 3 && placeHolder.type !== ride.airportType) || (ride.rentalType === 4 && placeHolder.type !== "2 hrs, 20 kms") || (ride.rentalType === 5 && placeHolder.type !== "4 hrs, 40 kms") || (ride.rentalType === 6 && placeHolder.type !== "8 hrs, 80 kms") || (ride.rentalType === 7 && placeHolder.type !== "12 hrs, 120 kms")){
                            this.props.dispatch(placeHolderOrder(address, this.state.selectedVendor, this.state.myChoizeBlockedVehicle));
                        }
                        this.openRazorpay()
                    }
                    this.setState({ completeReward: true })

                }
                else {
                    if(placeHolder.pickCity === ''){
                        this.props.dispatch(placeHolderOrder(address, this.state.selectedVendor, this.state.myChoizeBlockedVehicle));
                    }
                    else if(placeHolder.pickCity !== ride.pickup.city || placeHolder.dropCity !== ride.dest.city || placeHolder.pickAddress !== ride.pickup.loc || placeHolder.dropAddress !== ride.dest.loc || placeHolder.startDate !== ride.startDate || placeHolder.startTime !== ride.startTime || placeHolder.endDate !== ride.endDate || placeHolder.endTime !== ride.endTime || placeHolder.rentalType !== ride.rentalType || placeHolder.vendor !== selectedVendor.account_name || placeHolder.carCategotyId !== selectedCar.category_id || placeHolder.carSubCategoryId !== selectedCar.sub_category_id || (ride.rentalType === 1 && placeHolder.type !== ride.measureLookupId) || (ride.rentalType === 2 && placeHolder.type !== selectedVendor.measure_cd) || (ride.rentalType === 3 && placeHolder.type !== ride.airportType) || (ride.rentalType === 4 && placeHolder.type !== "2 hrs, 20 kms") || (ride.rentalType === 5 && placeHolder.type !== "4 hrs, 40 kms") || (ride.rentalType === 6 && placeHolder.type !== "8 hrs, 80 kms") || (ride.rentalType === 7 && placeHolder.type !== "12 hrs, 120 kms")){
                        this.props.dispatch(placeHolderOrder(address, this.state.selectedVendor, this.state.myChoizeBlockedVehicle));
                    }
                    this.openRazorpay();
                }

            }
            else {
                if(placeHolder.pickCity === ''){
                    this.props.dispatch(placeHolderOrder(address, this.state.selectedVendor, this.state.myChoizeBlockedVehicle));
                }
                else if(placeHolder.pickCity !== ride.pickup.city || placeHolder.dropCity !== ride.dest.city || placeHolder.pickAddress !== ride.pickup.loc || placeHolder.dropAddress !== ride.dest.loc || placeHolder.startDate !== ride.startDate || placeHolder.startTime !== ride.startTime || placeHolder.endDate !== ride.endDate || placeHolder.endTime !== ride.endTime || placeHolder.rentalType !== ride.rentalType || placeHolder.vendor !== selectedVendor.account_name || placeHolder.carCategotyId !== selectedCar.category_id || placeHolder.carSubCategoryId !== selectedCar.sub_category_id || (ride.rentalType === 1 && placeHolder.type !== ride.measureLookupId) || (ride.rentalType === 2 && placeHolder.type !== selectedVendor.measure_cd) || (ride.rentalType === 3 && placeHolder.type !== ride.airportType) || (ride.rentalType === 4 && placeHolder.type !== "2 hrs, 20 kms") || (ride.rentalType === 5 && placeHolder.type !== "4 hrs, 40 kms") || (ride.rentalType === 6 && placeHolder.type !== "8 hrs, 80 kms") || (ride.rentalType === 7 && placeHolder.type !== "12 hrs, 120 kms")){
                    this.props.dispatch(placeHolderOrder(address, this.state.selectedVendor, this.state.myChoizeBlockedVehicle));
                }
                this.openRazorpay();
            }
        }
       
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
        let uploadCount = 0;
        if (this.state.licenseFront !== '' && this.state.licenseBack !== '' && this.state.licenseNo !== '') {
            uploadCount += 2;
        }
        if (this.state.panCard !== '' && this.state.panNumber !== '') {
            uploadCount += 2;
        }
        if (this.state.aadharFront !== '' && this.state.aadharBack !== '' && this.state.aadharNo !== '') {
            uploadCount += 2;
        }
        if (this.state.passportDetailsPage !== '' && this.state.passportNo !== '') {
            uploadCount += 2;
        }
        if (this.state.utilityBill !== '' && this.state.utilityNo !== '') {
            uploadCount += 2;
        }
        if (uploadCount === 6 || uploadCount === 8 || uploadCount === 10) {
            this.setState({ proceedUpload: true })
            uploadCount = 8;
        }
        else {
            this.setState({ proceedUpload: false })
        }
    }

    onDrop = (file, type, uploading) => {
        console.log(file);
        this.setState({ [uploading]: true })
        if (file[0] === null || file.length > 1) {
            return;
        }
        else {
            Object.defineProperty(file[0], 'name', {
                value: type + '.' + file[0].type.split('/')[1],
                writable: false,
            })
            const config = {
                dirName: this.state.phone,
                bucketName: 'swing-partner',
                region: 'us-east-1',
                accessKeyId: 'AKIAIBRBFN353IM4CGFQ',
                secretAccessKey: '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',
            };

            S3FileUpload
                .uploadFile(file[0], config)
                .then(response => {
                    console.log(response);
                    this.setState({ [type]: response.location, [uploading]: false })
                    let uploadCount = 0;
                    if (this.state.licenseFront !== '' && this.state.licenseBack !== '' && this.state.licenseNo !== '') {
                        uploadCount += 2;
                    }
                    if (this.state.panCard !== '' && this.state.panNumber !== '') {
                        uploadCount += 2;
                    }
                    if (this.state.aadharFront !== '' && this.state.aadharBack !== '' && this.state.aadharNo !== '') {
                        uploadCount += 2;
                    }
                    if (this.state.passportDetailsPage !== '' && this.state.passportNo !== '') {
                        uploadCount += 2;
                    }
                    if (uploadCount === 6 || uploadCount === 8) {
                        this.setState({ proceedUpload: true })
                        uploadCount = 8;
                    }
                })
                .catch(err => {
                    console.error(err);
                    this.setState({ [uploading]: false })
                })
        }
    }

    onKeyPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            //   alert('Enter key pressed');
            this.handleNext();
            // write your functionality here
        }
        if (event.key === 'Enter' && event.shiftKey) {
            // alert('Shift+Enter key pressed');
            this.handlePrev();
        }
    }

    // handlePopupNext = () => {
    //     if (!this.state.custOtp) {
    //         if(this.state.customercare.length !== 10){
    //             this.setState({ error: "Please Enter a Valid Phone Number" });
    //             return;
    //         }
    //         this.setState({ custOtp: true, otp: '' });
    //         this.sendOtp()
    //     }
    //     else {
    //         this.authenticateOtp()
    //     } 
    // }

    handleCaptchaChange = (value) => {
        console.log("Captcha value:", value);
        this.setState({ captchaValue: value })
    }

    verifyCaptcha = () => {
        let url = urls.verifyCaptcha;
        let inputData = {
            "response": this.state.captchaValue,
            "remoteip": this.props.ip
        }
        console.log("Captcha URL", url, "\nCaptch Input", inputData)
        axios.post(url, inputData)
            .then(response => {
                console.log("Captcha Response", response)
                if (response.data.statusCD === 200) {
                    let responseData = JSON.parse(response.data.responseData);
                    if (responseData.success) {
                        this.generateToken();
                        this.setState({ step: this.state.step + 1 })
                    }
                    else {
                        alert("Duplicate captcha request or captcha timeout. Please try again.")
                    }
                }
                else {
                    alert("Cannot serve request at the moment. Please try again after some time")
                }
            })
            .catch(error => {
                console.log("Captcha Error", error)
                alert("Cannot serve request at the moment. Please try again after some time")
            })
    }

    render() {
        console.log("custotp",this.state.custOtp);
        return (
            this.state.showBookingForm && <SliderModal active={this.state.showBookingForm}>
                <div className={css`${tw`w-full -mx-2 px-4 py-12 lg:px-12 pb-24 h-full overflow-y-scroll relative z-500`}; z-index: 1000`}>
                    <div className={css`${tw`w-full flex px-4 justify-between items-center`}`}>

                        <div className={css`${tw`w-auto`}`}>
                            <p className={css`${tw`lg:text-xl text-md font-bold text-grey-dark`}`}>
                                <span className={css`${tw`text-lg block mb-2 font-normal font-medium opacity-70`}`}>{this.state.step === 1 ? 'You\'re awesome!' : ''}</span>
                                {this.state.step === 1 && 'What do we call you?'}
                                {this.state.step === 2 && 'Your Best Email?'}
                                {this.state.step === 3 && 'Best number to reach you?'}
                                {this.state.step === 4 && 'Enter the OTP recieved on your email'}
                                {/* {this.props.vendors.category === "SELF DRIVE" && this.state.step === 5 && 'Enter License Number'} */}
                                {this.props.vendors.category === "SELF DRIVE" && this.state.step === 5 && 'Enter your driver license details'}
                                {this.props.vendors.category === "SELF DRIVE" && this.state.step === 6 && 'Enter your Aadhar/PAN details'}
                                {this.state.step === 7 && !this.state.fetchingMyChoizeVehicle && 'Any special needs to pass on? (Optional)'}
                                {this.state.step === 8 && !this.state.isSubmitting && !this.state.fetchingMyChoizeVehicle && 'Pay to confirm the order'}
                                {this.state.step === 0 && this.state.isSubmitting && 'Submitting ... Please do not go back or refresh'}
                                {this.state.remarks !== 'customer care' && <span className={css`${tw`text-lg block mb-2 font-normal font-medium opacity-70 text-red`}`}>{this.state.error}</span>}
                                {this.state.step === 9 && "Enter Operations Agent Number"}
                                {this.state.step === 10 && "Enter Operations OTP" }
                            </p>
                        </div>

                        {/*<div className={css`${tw`w-auto flex justify-end`}`}>
                                    <StepsCircle height={'60'} currentStep={this.state.step} totalSteps={8} />
                            </div>*/}

                    </div>
                    <div className={css`${tw`w-full my-4 flex justify-between items-center`}`} onKeyPress={this.onKeyPress}>
                        {this.state.step === 1 &&
                            <input
                                // autofocus='true' ref={input => (this.input = input)} 
                                className={css`${tw`flex-1 bg-grey-light px-4 py-8 font-brand font-bold text-4xl outline-none shadow-none appearance-none rounded-lg border-none`}`}
                                placeholder={'Your Name?'} name="name" value={this.state.name} onChange={this.handleChange} />
                        }
                        {this.state.step === 2 &&
                            <input
                                // autofocus='true' ref={input => (this.input = input)} 
                                className={css`${tw`flex-1 bg-grey-light px-4 py-8 font-brand font-bold text-4xl outline-none shadow-none appearance-none rounded-lg border-none`}`}
                                placeholder={'Your Email?'} type="email" name="email" value={this.state.email} onChange={this.handleChange} />
                        }
                        {this.state.step === 3 &&
                            <div className={css`${tw`flex flex-col w-full`}`}>
                                {/* <ReactPhoneInput onlyCountries={['us', 'in']} containerClass={css`${tw`flex-1 bg-grey-light px-4 py-8 font-brand font-bold text-4xl outline-none shadow-none appearance-none rounded-lg border-none`}`}/> */}
                                <input
                                    // autofocus='true' ref={input => (this.input = input)} 
                                    className={css`${tw`flex-1 bg-grey-light px-4 py-8 font-brand font-bold text-4xl outline-none shadow-none appearance-none rounded-lg border-none`}`}
                                    placeholder={'Your Phone?'} type="number" name="phone" value={this.state.phone} onChange={this.handleChange} />
                                <div className={css`${tw`mt-4`}`}>
                                    <ReCAPTCHA
                                        sitekey="6LdbTqUZAAAAAKisDSJH7MgOxXb3vQwUVEBXBCb_"
                                        onChange={this.handleCaptchaChange}
                                    />
                                </div>
                            </div>
                        }
                        {this.state.step === 4 &&
                            <input
                                // autofocus='true' ref={input => (this.input = input)} 
                                className={css`${tw`flex-1 bg-grey-light px-4 py-8 font-brand font-bold text-4xl outline-none shadow-none appearance-none rounded-lg border-none`}`}
                                placeholder={'Enter OTP'} type="number" name="otp" value={this.state.otp} onChange={this.handleChange} />
                        }

                        {this.props.vendors.category === "SELF DRIVE" && this.state.step === 5 && this.state.idType === 'licenseNo' &&
                            // <ReactPhoneInput onlyCountries={['us', 'in']} containerClass={css`${tw`flex-1 bg-grey-light px-4 py-8 font-brand font-bold text-4xl outline-none shadow-none appearance-none rounded-lg border-none`}`}/>
                            <div className={css`${tw`flex flex-col w-full`}`}>
                                {/* <div className={css`${tw`flex flex-row inline-flex flex-wrap w-full justify-between`}`}>
                                        <div>
                                        <PaymentRadio
                                        value='licenseNo'
                                        onClick={(e) => { this.setState({ idType: 'licenseNo' }) }}
                                        active={this.state.idType === 'licenseNo'}>
                                        <div className={css`${tw`inline-flex self-center ml-1 w-1/6`}`}>
                                            <span className={css`${tw`block font-bold text-grey-dark lg:text-xl text-sm`}`}>
                                            Driving&nbsp;License
                       </span>
                                        {this.state.licenseFront !== '' && this.state.licenseBack !== '' && this.state.licenseNo !== '' && <span><img src={require('../assets/img/green-tick.png')} height="10" width="10" /></span>}
                                        </div>

                                    </PaymentRadio>  
                                    </div>
                                    <div className={css`${tw``}`}> 
                                        <PaymentRadio
                                                value='panNumber'
                                                onClick={() => { this.setState({ idType: 'panNumber' }) }}
                                                active={this.state.idType === 'panNumber'}>
                                                <div className={css`${tw`inline-flex self-center ml-1 w-1/6`}`}>
                                                    <span className={css`${tw`block font-bold text-grey-dark lg:text-xl text-sm`}`}>
                                                    Pan
                                </span>
                                        {this.state.panCard !== '' && this.state.panNumber !== '' && <span><img src={require('../assets/img/green-tick.png')} height="10" width="10" /></span>}
                                                </div>

                                            </PaymentRadio>
                                           </div>
                                    <div className={css`${tw``}`}> 
                                        <PaymentRadio
                                                value='aadharNo'
                                                onClick={() => { this.setState({ idType: 'aadharNo' }) }}
                                                active={this.state.idType === 'aadharNo'}>
                                                <div className={css`${tw`inline-flex self-center ml-1 w-1/6`}`}>
                                                    <span className={css`${tw`block font-bold text-grey-dark lg:text-xl text-sm`}`}>
                                                    Aadhar/PAN&nbsp;Card
                                </span>
                                        {this.state.aadharFront !== '' && this.state.aadharBack !== '' && this.state.aadharNo !== '' && <span><img src={require('../assets/img/green-tick.png')} height="10" width="10" /></span>}
                                                </div>

                                            </PaymentRadio>
                                           </div>
                                           <div className={css`${tw``}`}>
                                            <PaymentRadio
                                                value='passportNo'
                                                onClick={(e) => { this.setState({ idType: 'passportNo' }) }}
                                                active={this.state.idType === 'passportNo'}>
                                                <div className={css`${tw`inline-flex self-center ml-1 w-1/6`}`}>
                                                    <span className={css`${tw`block font-bold text-grey-dark lg:text-xl text-sm`}`}>
                                                        Passport
                              </span>
                                        {this.state.passportDetailsPage !== '' && this.state.passportNo !== '' && <span><img src={require('../assets/img/green-tick.png')} height="10" width="10" /></span>}
                                                </div>

                                            </PaymentRadio>
                                         </div>
                                         <div className={css`${tw``}`}> 
                                        <PaymentRadio
                                                value='utilityNo'
                                                onClick={() => { this.setState({ idType: 'utilityNo' }) }}
                                                active={this.state.idType === 'utilityNo'}>
                                                <div className={css`${tw`inline-flex self-center ml-1 w-1/6`}`}>
                                                    <span className={css`${tw`block font-bold text-grey-dark lg:text-xl text-sm`}`}>
                                                    Utility&nbsp;Bill/Student&nbsp;Id
                                </span>
                                        {this.state.utilityBill !== '' && this.state.utilityNo !== '' && <span><img src={require('../assets/img/green-tick.png')} height="10" width="10" /></span>}
                                                </div>

                                            </PaymentRadio>
                                           </div>
                                        </div> */}
                                <div>
                                    <input
                                        // autofocus='true' ref={input => (this.input = input)} 
                                        className={css`${tw`flex-1 bg-grey-light px-4 py-8 w-full font-brand font-bold text-4xl outline-none shadow-none appearance-none rounded-lg border-none`}`}
                                        placeholder={'Enter Number'} name={[this.state.idType]} value={this.state[this.state.idType]} onChange={this.handleChange} />
                                </div>
                                {this.state.idType === 'licenseNo' && <div className={css`${tw`lg:flex justify-around`}`}>
                                    <ImageUploader
                                        withIcon={false}
                                        // withPreview={false}
                                        singleImage={true}
                                        label='Driving License Front'
                                        buttonText={!this.state.uploadingLF ? (this.state.licenseFront === '' ? 'Choose Image' : 'Uploaded') : 'Uploading...'}
                                        onChange={(file) => { this.onDrop(file, 'licenseFront', 'uploadingLF') }}
                                        imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                                        maxFileSize={5242880}
                                        withPreview={true}
                                    />
                                    <div className={css`${tw`lg:flex hidden px-4`}`}></div>
                                    <ImageUploader
                                        withIcon={false}
                                        // withPreview={false}
                                        singleImage={true}
                                        label='Driving License Back'
                                        buttonText={!this.state.uploadingLB ? (this.state.licenseBack === '' ? 'Choose Image' : 'Uploaded') : 'Uploading...'}
                                        onChange={(file) => { this.onDrop(file, 'licenseBack', 'uploadingLB') }}
                                        imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                                        maxFileSize={5242880}
                                        withPreview={true}
                                    />
                                </div>}
                            </div>
                        }

                        {/* {this.state.idType === 'panNumber' && <div className={css`${tw`lg:flex justify-around`}`}>
                                <ImageUploader
                                    withIcon={false}
                                    withPreview={false}
                                    singleImage={true}
                                    label='Pan Card'
                                    buttonText={!this.state.uploadingPan ? (this.state.panCard === '' ? 'Choose Image' : 'Uploaded') : 'Uploading...'}
                                    onChange={(file) => { this.onDrop(file, 'panCard','uploadingPan') }}
                                    imgExtension={['.jpg', '.gif', '.png', '.gif','.jpeg']}
                                    maxFileSize={5242880}
                                />
                                <div className={css`${tw`lg:flex hidden px-4`}`}></div>
                                </div>} */}
                        {this.props.vendors.category === "SELF DRIVE" && this.state.step === 6 && this.state.idType === 'aadharNo' &&
                            <div className={css`${tw`flex flex-col w-full`}`}>
                                <div>
                                    <input
                                        // autofocus='true' ref={input => (this.input = input)} 
                                        className={css`${tw`flex-1 bg-grey-light px-4 py-8 w-full font-brand font-bold text-4xl outline-none shadow-none appearance-none rounded-lg border-none`}`}
                                        placeholder={'Enter Number'} name={[this.state.idType]} value={this.state[this.state.idType]} onChange={this.handleChange} />
                                </div>
                                {this.state.idType === 'aadharNo' && <div className={css`${tw`lg:flex justify-around`}`}>
                                    <ImageUploader
                                        withIcon={false}
                                        // withPreview={false}
                                        singleImage={true}
                                        label='Aadhar/Pan Card Front'
                                        buttonText={!this.state.uploadingAF ? (this.state.aadharFront === '' ? 'Choose Image' : 'Uploaded') : 'Uploading...'}
                                        onChange={(file) => { this.onDrop(file, 'aadharFront', 'uploadingAF') }}
                                        imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                                        maxFileSize={5242880}
                                        withPreview={true}
                                    />
                                    <div className={css`${tw`lg:flex hidden px-4`}`}></div>
                                    <ImageUploader
                                        withIcon={false}
                                        // withPreview={false}
                                        singleImage={true}
                                        label='Aadhar/Pan Card Back'
                                        buttonText={!this.state.uploadingAB ? (this.state.aadharBack === '' ? 'Choose Image' : 'Uploaded') : 'Uploading...'}
                                        onChange={(file) => { this.onDrop(file, 'aadharBack', 'uploadingAB') }}
                                        imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
                                        maxFileSize={5242880}
                                        withPreview={true}
                                    />
                                </div>}
                            </div>}
                        {/* {this.state.idType === 'passportNo' && <div className={css`${tw`lg:flex justify-around`}`}>
                                <ImageUploader
                                    withIcon={false}
                                    withPreview={false}
                                    singleImage={true}
                                    label='Passport Details Page'
                                    buttonText={!this.state.uploadingPDP ? (this.state.passportDetailsPage === '' ? 'Choose Image' : 'Uploaded') : 'Uploading...'}
                                    onChange={(file) => { this.onDrop(file, 'passportDetailsPage','uploadingPDP') }}
                                    imgExtension={['.jpg', '.gif', '.png', '.gif','.jpeg']}
                                    maxFileSize={5242880}
                                />
                                <div className={css`${tw`lg:flex hidden px-4`}`}></div>
                                <ImageUploader
                                    withIcon={false}
                                    withPreview={false}
                                    singleImage={true}
                                    label='Passport Last Page'
                                    buttonText={!this.state.uploadingPL ? (this.state.passportLastPage === '' ? 'Choose Image' : 'Uploaded') : 'Uploading...'}
                                    onChange={(file) => { this.onDrop(file, 'passportLastPage','uploadingPL') }}
                                    imgExtension={['.jpg', '.gif', '.png', '.gif','.jpeg']}
                                    maxFileSize={5242880}
                                />
                                </div>} */}
                        {/* {this.state.idType === 'utilityNo' && <div className={css`${tw`lg:flex justify-around`}`}>
                                <ImageUploader
                                    withIcon={false}
                                    withPreview={false}
                                    singleImage={true}
                                    label='Utility Bill/Student Id'
                                    buttonText={!this.state.uploadingUB ? (this.state.utilityBill === '' ? 'Choose Image' : 'Uploaded') : 'Uploading...'}
                                    onChange={(file) => { this.onDrop(file, 'utilityBill','uploadingUB') }}
                                    imgExtension={['.jpg', '.gif', '.png', '.gif','.jpeg']}
                                    maxFileSize={5242880}
                                />
                                </div>} */}


                        {this.state.step === 7 && !this.state.fetchingMyChoizeVehicle &&

                            <input
                                //  autofocus='true' ref={input => (this.input = input)} 
                                className={css`${tw`flex-1 bg-grey-light px-4 py-8 font-brand font-bold text-4xl outline-none shadow-none appearance-none rounded-lg border-none`}`}
                                placeholder={'Enter Here'} type="name" name="remarks" value={this.state.remarks} onChange={this.handleChange} />
                        }
                        
                        {this.state.step === 8 && !this.state.isSubmitting && !this.state.fetchingMyChoizeVehicle &&
                            <div className={css`${tw`block w-full px-4 `}`}>
                                <PaymentRadio
                                    onClick={() => this.setState({ payFull: true, payAdvance: false })}
                                    active={this.state.payFull}>
                                    <div className={css`${tw`inline-flex self-center ml-4`}`}>
                                        <p className={css`${tw`text-grey-darker text-sm`}`}>
                                          {this.state.redeemPoints && this.state.selectedVendor.ride_amt - this.state.selectedVendor.couponValue - this.props.user.profile.redeemablePoints <=0 ?  "Pay Security Deposit" : "Pay Full Amount"}
                                          
                                                    <span className={css`${tw`block font-bold text-grey-dark text-xl`}`}>
                                                &#8377; {
                                                    this.state.redeemPoints ?
                                                        ((this.state.selectedVendor.hasOwnProperty('security_deposit') ?
                                                            ((this.state.selectedVendor.ride_amt - this.state.selectedVendor.couponValue) - this.props.user.profile.redeemablePoints) > 0 ? ((this.state.selectedVendor.ride_amt - this.state.selectedVendor.couponValue) - this.props.user.profile.redeemablePoints) : ((this.state.selectedVendor.hasOwnProperty('security_deposit') ? this.state.selectedVendor.security_deposit  : 0))
                                                            :
                                                            ((this.state.selectedVendor.discountedValue - this.props.user.profile.redeemablePoints) > 0 ? (this.state.selectedVendor.discountedValue - this.props.user.profile.redeemablePoints) : ((this.state.selectedVendor.hasOwnProperty('security_deposit') ? this.state.selectedVendor.security_deposit  : 0)))
                                                        ).toLocaleString())
                                                        :
                                                        ((this.state.selectedVendor.ride_amt - this.state.selectedVendor.couponValue).toLocaleString())

                                                }
                                            </span>
                                        </p>
                                    </div>
                                </PaymentRadio>
                        
                                {this.state.selectedVendor.advance_amount !== 0 && this.state.selectedVendor.advance_percentage !== 100 &&
                                    <PaymentRadio
                                        onClick={() => this.setState({ payFull: false, payAdvance: true })}
                                        active={!this.state.payFull}>
                                        <div className={css`${tw`inline-flex self-center ml-4`}`}>
                                            <p className={css`${tw`text-grey-darker text-sm`}`}>
                                                Pay Only Advance Amount
                                                    <span className={css`${tw`block font-bold text-grey-dark text-xl`}`}>
                                                    &#8377; {
                                                        this.state.redeemPoints ?
                                                            ((((this.state.selectedVendor.advance_amount) - this.props.user.profile.redeemablePoints) > 0 ? ((this.state.selectedVendor.advance_amount) - this.props.user.profile.redeemablePoints) : ((this.state.selectedVendor.hasOwnProperty('security_deposit') ? this.state.selectedVendor.security_deposit : 0))).toLocaleString()) :
                                                            ((this.state.selectedVendor.advance_amount).toLocaleString())
                                                    }
                                                </span>
                                            </p>
                                        </div>
                                    </PaymentRadio>
                                }
                                {this.props.user.profile.hasOwnProperty('redeemablePoints') && this.props.user.profile.redeemablePoints > 0 &&
                                    <PaymentRadio
                                        onClick={() => { this.setState({ redeemPoints: !this.state.redeemPoints }) }}
                                        active={this.state.redeemPoints}>
                                        <div className={css`${tw`inline-flex self-center ml-4`}`}>
                                            <p className={css`${tw`text-grey-darker text-sm`}`}>
                                                Redeem Cashback
                                                    <span className={css`${tw`block font-bold text-grey-dark text-xl`}`}>
                                                    &#8377; {this.props.user.profile.redeemablePoints.toLocaleString()}
                                                </span>
                                            </p>
                                        </div>
                                    </PaymentRadio>
                                }
                            </div>
                        }
                        {((this.state.step === 0 && this.state.isSubmitting) || (this.state.fetchingMyChoizeVehicle)) &&
                            <div className={
                                css`${tw`w-full flex justify-center align-middle`}; 
                                    }`}>
                                <PulseLoader pColor='#7e00e1' />
                            </div>
                        }
                        {this.state.step === 9 && 
                            <input
                                //  autofocus='true' ref={input => (this.input = input)} 
                                className={css`${tw`flex-1 bg-grey-light px-4 py-8 font-brand font-bold text-4xl outline-none shadow-none appearance-none rounded-lg border-none`}`}
                                placeholder={'Enter Mobile Number'} type="name" name="customercare" value={this.state.customercare} onChange={this.handleChange} />
                        }
                          {this.state.step === 10 && 
                            <input
                                //  autofocus='true' ref={input => (this.input = input)} 
                                className={css`${tw`flex-1 bg-grey-light px-4 py-8 font-brand font-bold text-4xl outline-none shadow-none appearance-none rounded-lg border-none`}`}
                                placeholder={'Enter OTP'} type="number" name="otp" value={this.state.otp} onChange={(event) =>   ( this.setState({ [event.target.name]: event.target.value }) )                            } />
                        }
                    </div>
                    {!this.state.fetchingMyChoizeVehicle && <div className={css`${tw`w-full my-4 flex lg:justify-end items-center`}`}>
                        {(this.state.step > 1 && !this.state.isSubmitting) && <button onClick={this.handlePrev} className={css`${tw`w-full lg:w-1/4 px-4 py-4 mt-4 text-xl cursor-pointer outline-none shadow-none appearance-none bg-transparent hover:bg-grey-light rounded-md lg:mr-2 border-none`}`}>Prev</button>}
                        {(!this.state.isSubmitting && this.state.step !== 0) && <button onClick={this.handleNext}
                            // disabled={this.state.step === 5 && this.state.proceedUpload === false} 
                            className={css`${tw`w-full lg:w-1/4 px-4 py-4 mt-4 text-xl cursor-pointer appearance-none bg-grey-light rounded-md outline-none shadow-none hover:bg-white hover:shadow-lg border-none`}`}>Next</button>}
                    </div>}
                </div>

            </SliderModal>)
    }
}

const mapStateToProps = (state) => ({
    vendors: state.cars.vendors,
    ride: state.ride,
    isUserLoggedIn: state.user.isLoggedIn,
    selectedCar: state.order.selectedCar,
    userName: state.user.name,
    userPhone: state.user.phone,
    userEmail: state.user.email,
    user: state.user,
    selectedVendor: state.order.selectedVendor,
    myChoize: state.myChoize,
    placeHolder: state.placeHolder,
    ip: state.order.IP
})


export default connect(mapStateToProps)(ProfileSlider);